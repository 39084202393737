import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../../Utilities/Loading'
import { useTranslation } from 'react-i18next';
import { APIPost } from '../../API/APIRequest';
import { GetBasket } from './Basket';
import '../../../i18n';
import useBodyClass from  '../../../Hooks/useBodyClass';
import usePageTitle from '../../../Hooks/usePageTitle';
import { removeQueryParams } from '../../Utilities/Functions';
export function WorldpayResponseHandler(props) {
    
    const [errors, setErrors] = useState([]);
    const { t, i18n } = useTranslation();
    const { fid } = useParams();
    const { status } = useParams();
    const [currentAction, setCurrentAction] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    useBodyClass(['payment-process']);
    usePageTitle(`Payment Processing with Worldpay -  ${props.brandingElement?.id}`);
    var params = (new URL(document.location)).searchParams;
 

    const confirmPayment = () => {
        GetBasket({ "fid": fid, "loadfromserver": false }).then((basket) => {
            if (basket.offertypename !== "paid free trial") {
                APIPost({
                    "controller": "worldpay",
                    "action": "processpayment",
                    "environment": null,
                    "identifier": fid
                }).then((response) => {

                    let url=window.location.protocol + "//" + window.location.host + "/thankyou/" + response.data.OrderId ;
                    let updatedUrl= removeQueryParams(url, "thankyouPage");
                    window.location.href = updatedUrl;
                }).catch((error) => {


                });
            }
            else {
                APIPost({
                    "controller": "freetrial",
                    "action": "completetokenisedfreetrialsetup",
                    "environment": null,
                    "data": {
                        "fid": fid
                    }
                }).then((response) => {

                    let url=window.location.protocol + "//" + window.location.host + "/thankyou/" + response.data.OrderId ;
                    let updatedUrl= removeQueryParams(url, "thankyouPage");
                    window.location.href = updatedUrl;
                   
                }).catch((error) => {


                });
            }
        }).catch((error) => {

        });
       
    }
    if (!isProcessing)
    {
        setIsProcessing(true);
        confirmPayment();
    }

    return (
                <div className="container" aria-live="polite">
                        <div className="item-container">
                            <div className="item-wrapper">
                    <h1 className="module-heading" tabIndex="-1" >{t('worldpayHandling.title')} </h1>
                            </div>
                        </div> 
                        <div className="item-container">
                            <div className="item-wrapper">
                            <p>{t('worldpayHandling.message')} </p>
                            </div>
                        </div> 
                        <div className="item-container">
                            <div className="item-wrapper">
                            <Loading loading={loading} />
                            </div>
                        </div>
                </div> 
             
    )
}

