import React, { forwardRef, useState, useImperativeHandle, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../../Utilities/Loading'
import { useTranslation } from 'react-i18next';
import { APIPost } from '../../API/APIRequest'
import '../../../i18n';
import useBodyClass from  '../../../Hooks/useBodyClass';
import { gtmEventTriggers } from '../../Utilities/GtmFunctions';
import usePageTitle from '../../../Hooks/usePageTitle';
import { removeQueryParams } from '../../Utilities/Functions';

export function StripeResponseHandler(props) {
    
    const [errors, setErrors] = useState([]);
    const { t, i18n } = useTranslation();
    const { fid } = useParams();
    const [currentAction, setCurrentAction] = useState([]);
    const [loading, setLoading] = useState(true);
    useBodyClass(['payment-process']);
    usePageTitle(`Payment Processing with stripe -  ${props.brandingElement?.id}`);
    var params = (new URL(document.location)).searchParams;
    var setupIntentId= params.get("setup_intent");
    var paymentIntentId= params.get("payment_intent");
     
   const ProcessStripePayment = () => {
       if (currentAction.length == 0) {
           if (setupIntentId) {
               currentAction.push("ProcessingFTSetup");
               APIPost({
                   "controller": "freetrial",
                   "action": "completetokenisedfreetrialsetup",
                   "data": {
                       "fid": fid
                   },
                   "environment": null,
                   "identifier": null
               }).then((ftresponse) => {
                   console.log(ftresponse);
                   let url= window.location.protocol + "//" + window.location.host + "/thankyou/" + ftresponse.data.OrderId ;
                   let updatedUrl= removeQueryParams(url, "thankyouPage");
                   window.location.href = updatedUrl;
                   
               }).catch((fterror) => {
                  
               });
           }
           if (paymentIntentId) {
                currentAction.push("ProcessingPayment");
                APIPost({
                    "controller": "stripe",
                    "action": "processpayment",
                    "data":
                    {
                        "fid": fid,
                        "paymentIntentId": paymentIntentId
                    }
                }).then((response) => {
                 
                    let url=  window.location.protocol + "//" + window.location.host + "/thankyou/" + response.data.OrderId 
                    let updatedUrl= removeQueryParams(url, "thankyouPage");
                    window.location.href = updatedUrl;
                    
            
                }).catch((error) => {
               
                })
           }
        }  
    }


    ProcessStripePayment();

    useEffect(()=>{
        props.setFid(fid);   //to pass to metadata extraction
      },[fid]) 

    return (
                <div className="container" aria-live="polite">
                        <div className="item-container">
                            <div className="item-wrapper">
                    <h1 className="module-heading" tabIndex="-1" >{t('stripeHandling.title')} </h1>
                            </div>
                        </div> 
                        <div className="item-container">
                            <div className="item-wrapper">
                    <p>{t('stripeHandling.message')} </p>
                            </div>
                        </div> 
                        <div className="item-container">
                            <div className="item-wrapper">
                                 <Loading loading={loading} />
                            </div>
                        </div>
                </div> 
             
    )
}

