import React, { useState,useEffect } from 'react';
import { ProcessErrors } from '../Utilities/ErrorBox';
import { Loading } from '../Utilities/Loading';
import { APIGet, APIPost, APIRedirect, getEnvironment} from '../API/APIRequest';
import { GetConfiguration, GetCurrentBrand } from '../Configuration/ConfigurationManager';
import { GetADToken, SaveADToken, RefreshADToken, GetADRefTokenLocal, RemoveADToken, RemoveADRefToken, SaveADRefToken } from '../Utilities/TokenManager'
import { GetCookieName, GetCookieValue, AuthenticateWithServerCookie, DeleteCookie, SaveCookie} from '../Utilities/ADFECookie'
const AuthContext = React.createContext()

function useProvideMiddlewareAuth() {
   
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [errors, setErrors] = useState([]);
    const [webAuth, setWebAuth] = useState(null)
    const [environment, setEnvironment] = useState(getEnvironment());
    const [currentAction, setCurrentAction] = useState([]);
    const [returnUrl, setReturnUrl] = useState(null);



    const getCurrentBrand=()=>{
        GetCurrentBrand(environment).then((brand) => {
            if (brand?.returnurl) { 
                sessionStorage.setItem("ReturnUrl", brand.returnurl);
                sessionStorage.setItem("CookieDomain", brand.cookiedomain);
                setReturnUrl(brand.returnurl);
            }
            //resolve(returnUrl);
        }).
        catch((error) => {
            //reject(error);
        })
    
     }
    
    //only will run in case of no cookie domain and no returnUrl
    useEffect(()=>{
     if(!sessionStorage.getItem("CookieDomain")){
        getCurrentBrand();
        }

      }, [])

    const params = new Proxy(new URLSearchParams(window.location.search),
        {
            get: (searchParams, props) => searchParams.get(props),
        });

   
    
    const ReturnUrl = () => {
        
        let params = (new URL(document.location)).searchParams;
        let retUrl = params.get("ReturnUrl");
        if (retUrl){

            setReturnUrl(retUrl);
            return retUrl;

            } 

        if (!returnUrl ) {
              //return new Promise((resolve, reject) => {
        let params = (new URL(document.location)).searchParams;
        let retUrl = params.get("ReturnUrl");
           
            if (!retUrl) {
                retUrl = document.referrer;
            }
            if (!retUrl) {
                retUrl = sessionStorage.getItem("ReturnUrl");
            }
            if (retUrl) {
                setReturnUrl(retUrl);
                //resolve(returnUrl);
            }
            if (!retUrl) {
                getCurrentBrand();
            }

        }
        return returnUrl;
        //})
    }

    const ClearUserData = () => {
        RemoveADToken();
        setUserData(null);
    }

    const ReloadUserData = () => {       
        getUserToken().then((userToken) => {
            currentAction.pop();
            setUserData(userToken);      
        }).catch((error) => {
            errors.push(ProcessErrors("error.unexpected"));
        });
    }


    const AuthenticateWithCookie = () => {
        return new Promise(async(resolve, reject) => { 
            try{
            let user = await AuthenticateWithServerCookie();

            if (user) {
                let userToken = {
                    "name": user.userfullname,
                    "token": user.userToken,
                    "partyid": user.partyid,
                    "username": user.username
                };
                SaveADToken(userToken);
                resolve(userToken);
            }
            else{
                resolve(null);
            }
        }catch(error){
            reject(error);
        }});

    }
  
    const getUserToken = () => {
        return new Promise(async(resolve, reject) => {
            currentAction.push("getusertoken");
            let refToken = params.ref;
            let userToken = GetADToken();   

            console.log(userToken);
          

            //if (userToken && !refToken)
            //{
            //    GetCookieName().then((cookieName) => {
           
            //        var cookieValue = GetCookieValue(cookieName);
       
            //        if (!cookieValue) {
            //            RemoveADToken();
            //            setUserData(null);
            //            resolve(null);
            //        }
            //    });
            //}
                                
            if (userToken?.token) {
                resolve(userToken);
                return;
            }

            if (!userToken?.token && !refToken) {
                let userToken= await AuthenticateWithCookie().catch((aerrors) => {

                  //in case of no cookie or any other error it should resolve with anon:true and redirect to middleware
                    let userToken = {
                        "anon" : true
                    }; 
                    resolve(userToken);

                });

                    if (userToken?.token) {
                        resolve(userToken);
                        return;
                    }
                
            }

            if (!userToken?.token && refToken) {
             
                let result = await APIGet({
                    "controller": "auth",
                    "action": "getuserbyreftoken",
                    "identifier": refToken
                }).catch((errors) => {
                    reject(errors);
                });

                let user = result?.data?.user;
           
                if(user){
                let userToken = {
                    "name": user.userfullname,
                    "token": user.userToken,
                    "partyid": user.partyid,
                    "username": user.username
                };
                SaveADToken(userToken);
                resolve(userToken);
                return;
                }
                
            }
            else {
                let userToken = {
                    "anon" : true
                }; 
                resolve(userToken);
            }                           
        });

    }

    const GetADRefToken = () => {
        let refToken = params.refToken;
        if (!refToken) {
            refToken = GetADRefTokenLocal();
        }
        return refToken;
    }
  
   
    if (currentAction.length === 0 && !userData && isLoading) {
        if(window.location){
            const searchParams = new URLSearchParams(window.location.search);
            const discountApplied = searchParams.get("discountApplied");   //check for discount flow, to prevent removal of adToken

            let url=window.location.pathname; 
            let urlParams=  url.split("/");
            if(urlParams.includes("checkout")){
               if(!discountApplied){
                 RemoveADToken();
               }
               
            }
        }
        if (!currentAction.includes("getusertoken")) {
            getUserToken().then((userToken) => {
                currentAction.pop();
             
                setUserData(userToken);
                setIsLoading(false);

            }).catch((error) => {
               
                errors.push(ProcessErrors("error.unexpected"));
                setIsLoading(false);
            });
        }
    }
    else {
        var sessionToken = GetADToken()
        if (!userData && sessionToken) {
            setUserData(sessionToken);
            
        }
        
    }
    return {
        environment,
        userData,
        isLoading,
        setErrors,
        errors,
        ReturnUrl,
        GetADRefToken,
        ClearUserData,
        ReloadUserData,
    }
}

function AuthenticationProvider(props) {
    
    var auth = useProvideMiddlewareAuth();
    
    if (auth.isLoading) {
        return (<Loading loading={true} class={"center-column"} />);
    }
    else {
        return (<AuthContext.Provider value={auth} {...props} />)
    }
}


const useAuth = () => {
    return React.useContext(AuthContext);
}
export {AuthenticationProvider, useAuth}