import { useTranslation } from 'react-i18next';
import useCreateUrl from '../../../Hooks/useCreateUrl';
import '../../../i18n';
import { useCallback, useEffect, useState } from "react";
import { format } from 'react-string-format';
import "./User.scss"
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import { emailRegexUtils } from '../../Utilities/EmailRegex';
import { ProcessErrors } from '../../Utilities/ErrorBox';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import setStateAsync from '../../Utilities/SetStateAsync';
import { checkIsBlank, checkIsInvalid } from '../../Utilities/FieldValidations';
import Button from '../../UI/Buttons/Button';
import { APIPost, getEnvironment } from '../../API/APIRequest';
import { useAuth } from '../../Authentication/AuthenticationProvider';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SaveADToken } from '../../Utilities/TokenManager';
import { SaveSessionCookie } from '../../Utilities/ADFECookie';
import { splitName } from '../../Utilities/Functions';


export function GuestUser({ userEmail, adToken, setAlreadyPurchased, setPurchaseRestriction, guestSession, setGuestSession, loggedIn, originatingurl, basket, setSectionComplete, hasRecaptcha }) {
  const createUrl = useCreateUrl()
  const { t, i18n } = useTranslation();

  let text = format(t('checkout.usersignedintext'),)
  const [IsBlank, setIsBlank] = useState({ firstName: false, lastName: false, userEmail: false });
  const [IsInvalid, setIsInvalid] = useState({ userEmail: false });
  const [guestDetails, setGuestDetails] = useState("");
  const [guestDetailsSaved, setGuestDetailsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetGuestDetails, setResetGuestDetails] = useState(true);
  let auth = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const purchasingFor=params.get("purchasingFor");
  const navigate = useNavigate();
  const [reCaptchaError, setReCaptchaError]= useState(false)
  
  const { executeRecaptcha } = useGoogleReCaptcha();
  let environment = getEnvironment();
  //recaptcha verification
  const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        
          return;
      }try{

          const recaptchaToken = await executeRecaptcha('guestlogin');
          return recaptchaToken;

      } catch(err){
          //in case of invalid recaptcha key
          return "invalidKey";
      }
    
      // we will use the token in recaptchaVerified()
  }, [executeRecaptcha]);

  const getResetStatus=()=>{
    return resetGuestDetails
  }
  
  const getEnvHostname=()=>{
 
  let environment=window.location.hostname;

  //supplying env to verifyRecaptcha call

  if(environment==="localhost"){
     return "ad-frontend.abasoft.co.uk";

  }else{
     return environment;
   }
}

  //recaptcha verification
  const recaptchaVerified = async() => {
    
    const recaptchaToken = await handleReCaptchaVerify();

    //if recaptcha is incorrect
    if(recaptchaToken === "invalidKey"){
      
        return true;  //it should work without the recaptcha as well and will return true in case of invalid key
    }
       
       let bodyFormData = new FormData();
       bodyFormData.append('token', recaptchaToken);
     
      const res = await APIPost({

           "controller": "security",
           "action": "verifyrecaptcha",
           "environment" : getEnvHostname(),
           data: bodyFormData,

       }).catch((errors) => { ProcessErrors(errors) })

       if (res.data.success) {

           if (res.data.score > 0.5) {
               return true;    //probable human activity
           }

           else setReCaptchaError(true)   //probabale bot activity
       }
       return false;
}


  const SaveUserDetails = async(e) => {

   
    e.preventDefault();
    const isHuman= hasRecaptcha ? await recaptchaVerified() : true;  
 
    if(ValidationOnSubmit() && isHuman){

    setIsLoading(true)  
     
    setResetGuestDetails(false);
 

    APIPost({

      "controller": "auth",
      "action": "createguestuser",
      "environment": environment,

      data: {
        email: guestDetails.userEmail,
        firstName: guestDetails.firstName,
        surname: guestDetails.lastName,
      }
    }).then((response) => {
      //TODO: add value
    
      let user = response.data.user;
     
      if(user){
      let guestUser = {
        "name": user.userfullname,
        "token": user.userToken,
        "username": user.username,
        "partyid": user.partyid,
        "isGuest":true
      };
    
      SaveADToken(guestUser);
      SaveSessionCookie(user.frontendCookieName , user.frontendCookieValue);
     
      //if begin_checkout has already fired on guest so it shouldn't fire on payment
      let url='/payment/' + basket.id + window.location.search 

      navigate(url, {state:{guestUser, guestSession: true }, replace: true}); 
      }
    }).catch((error) => {
      
      setIsLoading(false)
  });
 }
}
 const handleOnSubmit = (e, fieldType) => {
    Validation(e, fieldType) //TODO
  }

  const handleOnBlur = (e, fieldType) => {
    Validation(e, fieldType);
  }

  const ValidationOnSubmit = () => {
  
    let detailsFilled=true;
  
    for(let key in IsBlank){

      if(!guestDetails[key] || guestDetails[key]===""){
        setIsBlank(IsBlank => ({ ...IsBlank, [key]: true }))
        detailsFilled=false;

      }}
     
      if(guestDetails.userEmail){

      if(checkIsInvalid(guestDetails.userEmail, "userEmail")){
        setIsInvalid(IsInvalid => ({ ...IsInvalid, userEmail: true }))
        detailsFilled=false;

      }}

      return detailsFilled;
    }

  const Validation = (e, fieldType) => {
    let value = e.target.value;
    let type = fieldType;
    let isValid= false;
   
    setStateAsync(() => {
      if (checkIsBlank(value)) {
        setIsBlank(IsBlank => ({ ...IsBlank, [fieldType]: true }))
        }
      else {
        setIsBlank(IsBlank => ({ ...IsBlank, [fieldType]: false }))
      
        if (checkIsInvalid(value, type)) {
          setIsInvalid(IsInvalid => ({ ...IsInvalid, [fieldType]: true }))
          
        }
        else {
          setIsInvalid(IsInvalid => ({ ...IsInvalid, [fieldType]: false }))
        }

      }
    })
}

  const onTextFieldKeyed = (event) => {
    if ((event.key.match(emailRegexUtils) || []).length > 0) {
      event.preventDefault();
    }
  }

 useEffect(()=>{


  let guestUserDetails= location.state ? location.state.guestUser : (adToken?.isGuest === true) ? adToken : ""   //retain values either from location or adToken
  
  if(guestUserDetails){
    
    let tempname= guestUserDetails.name;
    let email=guestUserDetails.username;
    let guestName=splitName(tempname);
    setGuestDetails(guestDetails=>({...guestDetails, firstName:guestName[0], lastName:guestName[1] , userEmail: email}));
    }

   },[])

  const editMode=()=>{
   setGuestDetailsSaved(false);
   setSectionComplete({ ...setSectionComplete, deliveryDetails: false });
   sessionStorage.removeItem("CheckoutStarted");
   sessionStorage.removeItem("sessionDeliveryDetails"); //when we log out from guest session, this needs to fire again when user logs in again
   setGuestSession(false);
   setAlreadyPurchased({"status":"", "text": ""})
   setPurchaseRestriction({"status":"", "text": ""})
  }

 return (<div className="guest-user">
    {/*Block with saved details*/}
    { guestSession ?
      <div className="guest-user-block">
        <button className="edit-guest-details" onClick={() => { editMode()}}>Edit</button>
        <div className="guest-user-details">
          <p>{guestDetails.firstName} {guestDetails.lastName}</p>
          <p>{guestDetails.userEmail}</p>
        </div>
      </div>
      : <form onSubmit={SaveUserDetails}>
           {/* Guest Details Form */}
          
        <div className="guest-user-row">
          <div className="item-container">
            <div className="item-wrapper">

              <InputWrapperRefacture
                label={t('register.firstname')}
                inputSize="full-width"
                id="firstname"
                inputFieldType="text"
                onBlur={(e) => { handleOnBlur(e, "firstName") }}
                specs="text_input"
                name="firstname"
                value={guestDetails?.firstName}
                type="text"
                invalidClass={IsBlank.firstName && "Invalid-input"}
                onKeyPress={onTextFieldKeyed}
                aria-invalid={IsBlank.firstName}
                aria-describedby="firstname-blank-error"
                onChange={(e) => { setGuestDetails(guestDetails => ({ ...guestDetails, firstName: e.target.value })) }}
              />

              <span role="alert" id='firstname-blank-error'>{IsBlank.firstName && <ErrorBox>{t('register.blankfirstname')}</ErrorBox>}</span>
            </div>
          </div>

          <div className="item-container">
            <div className="item-wrapper">

              <InputWrapperRefacture
                label={t('register.lastname')}
                inputSize="full-width"
                inputFieldType="text"
                id="lastname"
                onBlur={(e) => { handleOnBlur(e, "lastName") }}
                name="lastname"
                type="text"
                value={guestDetails?.lastName}
                invalidClass={IsBlank.lastName && "Invalid-input"}
                specs="text-input"
                onKeyPress={onTextFieldKeyed}
                aria-invalid={IsBlank.lastName}
                aria-describedby="lastname-blank-error"
                onChange={(e) => { setGuestDetails(guestDetails => ({ ...guestDetails, lastName: e.target.value })) }}
              />

              <span role="alert" id="lastname-blank-error">{IsBlank.lastName && <ErrorBox>{t('register.blanklastname')}</ErrorBox>}</span>
            </div>
          </div>
        </div>

        <div className="auth-row">
          <div className="item-container">
            <div className="item-wrapper">

              <InputWrapperRefacture label={t('register.emaillabel')}
                inputSize="medium"
                inputFieldType="text"
                id="email"
                name="email"
                type="email"
                description={t('guest.emaildescription')}
                onBlur={(e) => { handleOnBlur(e, "userEmail") }}
                placeholder={t('placeholder.email')}
                value={guestDetails?.userEmail}
                onKeyPress={onTextFieldKeyed}
                invalidClass={(IsBlank.userEmail || IsInvalid.userEmail) ? "Invalid-input" : ""}
                aria-invalid={(IsBlank.userEmail || IsInvalid.userEmail)}
                aria-describedby="email-errors"
                onChange={(e) => { setGuestDetails(guestDetails => ({ ...guestDetails, userEmail: e.target.value })) }}
                readOnly={""} />

              <span role="alert" id="email-errors" >
                {IsBlank.userEmail && <ErrorBox>{t('login.blankemailinput')}</ErrorBox>}
                {/* ? props.brandingElement?.mailValidError-----TODO */}
                {/* <ErrorBox> {getBrandedHtml('mailValidError')} </ErrorBox> : */}
                {IsInvalid.userEmail ? <ErrorBox>{t('login.incorrectemailinput')}</ErrorBox> : ""}
              </span>
            </div>
          </div>
          {!isLoading ?
              <Button type="submit" buttonSize="small">{t('guest.submitbuttontext')}</Button>
                  : isLoading ?
                          <Button type="submit" hasIcon="updating" isDisabled="true" buttonSize="small">{t('guest.submitbuttontext')}</Button>
                                : ""}

        </div>
      

     
       
      </form>}

  </div>
  );
}


