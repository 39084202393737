import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext, useRef } from 'react';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import Dropdown from '../../UI/Dropdown/Dropdown';
import { Company } from '../../Checkout/Company/Company';
import AddressSearch from 'react-loqate';
import SearchDropdown from '../../UI/Dropdown/SearchDropdown';
import { updateBillingAddress, updateDeliveryAddress, deliveryCompany } from '../PaymentFormReducer';
//import 'react-loqate/dist/react-loqate.cjs.development.css';
import '../../../i18n'
import { PaymentFormContext } from '../PaymentFormReducer';
import { checkIsBlank } from '../../Utilities/FieldValidations';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import { useLoqate } from 'react-loqate';
import { format } from 'react-string-format';
import { timeout } from 'workbox-core/_private';
import ErrorBoundary from './ErrorBoundary';
import LoqateAddressFinder from '../../Utilities/LoqateAddressFinder';
import { APIPost } from '../../API/APIRequest';
import { loqateRequestLogs } from '../../Utilities/Functions';

export function AddressFields(props) {
    const { t, i18n } = useTranslation();
    const {hasVatChanged, isChecked, addressType, initialFilledState, showCompany, loqateKey, showContinueButton, countryCode, setShowContinueButton, countryRef, selectedCompany, setSelectedCompany, setSelectedAddress, selectedAddress,
        IsFilled, setIsFilled, editMode, addressStructure, companyProperties, setCompanyProperties } = props;
    const [inputWidth, setInputWidth] = useState("");
    const [addressFormat, setAddressFormat] = useState("LOOK_UP");
    const [showAddressLookupDropdown, seShowAddressLookupDropdown] = useState(false)
    const [showAddressLookUp, setShowAddressLookUp] = useState(editMode ? false : true);
    const [lookupInputFocused, setLookupInputFocused] = useState(false);
    const fieldsToNotMap = ["JOBTITLE", "DEPARTMENT"];
    const [loqateProps, setLoqateProps]= useState("")
    const lookupref = useRef(null);
    const listref = useRef(null);
    const myRef = useRef(null);

   
    
    const handleOnBlur = (value, field, mandatory) => {
        setIsFilled({ ...IsFilled, [field]: { filled: !checkIsBlank(value), mandatory: mandatory } })
    }


    //On mounting
    useEffect(() => {
        if (addressType === "deliveryAddress") {
            setShowAddressLookUp(!editMode)
        }

    }, [])

    useEffect(() => {

        if (addressType === "deliveryAddress") {
            if (countryRef.current !== countryCode && countryCode !== undefined) {
                setShowAddressLookUp(true)
                countryRef.current = countryCode
            }
        }
        if (addressType === "billingAddress") {
            setShowAddressLookUp(true)
            setSelectedCompany({COMPANY: "", CompanyId:null })
        }
    }, [countryCode])



    //when the billing address is the same checkbox is unchecked
    useEffect(() => {
        if (!isChecked && addressType === "billingAddress") {
            setAddressFormat("LOOK_UP");
        }
    }, [isChecked])


    //handler for when filling the address details manually
    const handleAddressInput = (value, name, mandatory) => {
        // setIsFilled(IsFilled => ({ ...IsFilled, [name]: { filled: !checkIsBlank(value), mandatory: mandatory } }))
        setSelectedAddress(selectedAddress => ({ ...selectedAddress, [name]: value, SOURCE: addressFormat }))

    }


    //***last set of changes
    //to map country specific address structure fields to the corresponding loqate fields, we have mappingArray
    const mapLoqateFields = (loqateProps) => {
        let manualFocused=false;
        let tempIsFilled={...IsFilled};
       
        const mappingArray = {
            COUNTRY: "CountryName",
            ADDRESS1: "Line1",
            TOWN: "City",
            POSTCODE: "PostalCode",
            COMPANY: "Company",
            ADDRESS2: "Line2",
            ADDRESS3: "Line3",
            COUNTY: "ProvinceName"
        };
       
      
        addressStructure.map((field, i) => {
            let key = field.fieldName
            if (!fieldsToNotMap.includes(key)) {
                let loqateValue = loqateProps[mappingArray[key]]

                //when hideCompany is on, preventing population of companyField while mapping fields
             
                if (key === "COMPANY" && showCompany) {
                    
                    if(loqateValue){
                        setSelectedCompany({ [key]: loqateValue, CompanyId: null });
                        tempIsFilled={...tempIsFilled, [key]: {...tempIsFilled[key], filled:true, mandatory:field.mandatory}}   //for error states
                    }
                    else{

                        tempIsFilled={...tempIsFilled, [key]: {...tempIsFilled[key], filled:false, mandatory:field.mandatory}}

                    }
                  
                   
                    // if(addressType==="billingAddress" && loqateValue !== ""){
                    // const element= document.getElementById("Credit/debit card");
                    // element.focus();
                    // manualFocused=true;  //when company field has been filled card list item will be focused..may change it to array[0] later
                    // }
                }
             
                else if(key !== "COUNTRY" && key !== "COMPANY" ) {

                    if(loqateValue){
                        setSelectedAddress(selectedAddress => ({ ...selectedAddress, [key]: loqateValue }));
                        tempIsFilled={...tempIsFilled, [key]: {...tempIsFilled[key], filled: true, mandatory:field.mandatory}}
                    }
                    else{
                       
                        tempIsFilled={...tempIsFilled, [key]: {...tempIsFilled[key], filled: false, mandatory:field.mandatory}}
                    }

                }}

        })
          
        tempIsFilled={...tempIsFilled, ADDRESS: {mandatory: true, filled:true}}
        setIsFilled({...tempIsFilled});
      
       if(setShowContinueButton){
        setShowContinueButton(true)
        }
        setSelectedAddress(selectedAddress => ({ ...selectedAddress, SOURCE: "LOQATE_API" }));
        setShowAddressLookUp(false)  //as the fields fill up, the look up will go away
        //for focusing on either company field or credit/debit option as per DOM elements after the field mapping

        if(addressType==="billingAddress" && !manualFocused){
            const element= document.getElementById("company"); //in case there is company field but blank
            if(element){
                element.focus();
            }
            else{
              const el=document.getElementById("Credit/debit card");  //in case there is no company field
              if(el){
                el.focus();
            }
            } }
          
            loqateRequestLogs(loqateProps.Id, loqateProps, 1)  //for logging successful loqate calls - address selection

           }
    

   
    

  //handle button click for as we toggling between manual or lookup
    function handleLinkClick(e, format) {

     e.preventDefault();

        if (format === "MANUAL") {
            setAddressFormat("MANUAL");

            if(setShowContinueButton){
                setShowContinueButton(true)
             }
            
            //so that it goes back to that original mapped filled state
            setIsFilled(IsFilled => ({ ...initialFilledState, ADDRESS: { filled: true, mandatory: true }, COUNTRY: { ...IsFilled.COUNTRY } })) 
        }
        else {
            //on state change to lookup-address filled state should say true so to not give an error initially
            if(setShowContinueButton){
                setShowContinueButton(false)
             }
            setIsFilled(IsFilled => ({ ...initialFilledState, ADDRESS: { filled: true, mandatory: true }, COUNTRY: { ...IsFilled.COUNTRY }}));
            setAddressFormat("LOOK_UP");
            setShowAddressLookUp(true);
        }
        setSelectedAddress(selectedAddress => ({ COUNTRY: selectedAddress.COUNTRY })) //as country should stay always
        //only in case of lookup
       
            setSelectedCompany({COMPANY: "", CompanyId: null })
       
       

    }

    useEffect(()=>{

            const element = document.getElementsByClassName('address-styled-list')[0];
            if (element) {
                element.style.display = "none"
            }

    },[hasVatChanged])


    

    //mapping of fields we getting in our address structure call
    let addressfield = addressStructure?.map((field, i) => {
        //as we have company dropdown there
        if (field.fieldName != "JOBTITLE" && field.fieldName != "COUNTRY" && field.fieldName != "DEPARTMENT" && field.fieldName != "COMPANY") {

            return <div className="item-container">
                <div className="item-wrapper">
                    <InputWrapperRefacture
                        label={field.label}
                        id={field.fieldName}
                        name={field.fieldName}
                        type="text"
                        inputFieldType="text"
                        placeholder={field.placeHolderText}
                        optionalField={!field.mandatory}
                        invalidClass={((IsFilled[field.fieldName] && !IsFilled[field.fieldName]?.filled)) && field.mandatory && "Invalid-input"}
                        aria-invalid={((IsFilled[field.fieldName] && !IsFilled[field.fieldName]?.filled)) && field.mandatory}
                        aria-describedby={`${[field.fieldName]}-errors`}
                        inputSize="full-width"
                        value={selectedAddress[field.fieldName]}
                        onBlur={(e) => handleOnBlur(e.target.value, [field.fieldName], field.mandatory)}
                        onChange={(e) => handleAddressInput(e.target.value, [field.fieldName], field.mandatory)}
                    />

                    {((IsFilled[field.fieldName] && !IsFilled[field.fieldName]?.filled)) && field.mandatory &&
                        <span role="alert" id={`${[field.fieldname]}-errors`}>
                            <ErrorBox>{format(t('addressfields.blankerrortext'), field.label)}</ErrorBox>
                        </span>
                    }

                </div>
            </div>
         }
    })

    //buttonUi for changing manual to lookup
    const changeAddressFormatLink = (format) => {
        if(showContinueButton || addressType==="billingAddress"){
            return <div className="item-container"> 
               <div className="item-wrapper">
                   <a href="#" onClick={(e) => { handleLinkClick(e, format) }}><strong>{format === "LOOK_UP" ? t('address.uselookuplabel') : t('address.usemanuallabel')}</strong></a>
                </div>
              </div>
       }
        else {
            return <a href="#" onClick={(e) => { handleLinkClick(e, format) }}><strong>{format === "LOOK_UP" ? t('address.uselookuplabel') : t('address.usemanuallabel')}</strong></a>
        }
     }

    //address section UI
    const addressSection = <>{addressfield}
        {addressType === "deliveryAddress" && showCompany && companyProperties && <Company companyList={props.companyList}
            companyProperties={companyProperties}
            addressType="deliveryAddress"
            IsFilled={IsFilled}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
        />}
        {changeAddressFormatLink("LOOK_UP")}
    </>


    const handleOnBlurLookup = (e) => {

      
        if (e.relatedTarget === null || e.relatedTarget.innerText === "Enter Address Manually") {
            const element = document.getElementsByClassName('address-styled-list')[0];
            if (element) {
                element.style.display = "none"
            }
        }
    }

    const handleOnFocusLookup = (e) => {

        const element = document.getElementsByClassName('address-styled-list')[0];
        if (element) {
            if (lookupref?.current?.value) {
                element.style.display = "block"
            }
        }
    }

   useEffect(()=>{
     
      if(!showAddressLookUp){
           let element = document.querySelector('.pca');
           if (element) {
              element.style.display = 'none';
            }}
       
     if(showAddressLookUp){
            let element = document.querySelector('.pca');
                if (element) {
                   element.style.display = 'block';
                 }}
                  
    }, [showAddressLookUp])

   useEffect(()=>{

        if(loqateProps){
            mapLoqateFields(loqateProps)
        }

    },[loqateProps])

    
    //address look up that shows up with showaddressformat===true
    let addressLookup = <> 
       
        <div className="item-container">
           <div className="item-wrapper" ref={listref} >
               <LoqateAddressFinder 
                  setLoqateProps={setLoqateProps}
                  options = {{
                  key: loqateKey,
                  search: { countries: countryCode}, 
                  setCountryByIP: false,
                  // GeoLocationEnabled: true,
                  // GeoLocationRadius: 50,
                  GeoLocationMaxItems: 10
                }}
             />
        
            <div className="item-for-screen-reader" aria-live="polite"></div>
         
            <InputWrapperRefacture
                className="full-width"
                inputSize="full-width"
                inputFieldType="text"
                id="search"
                name="address-search"
                ref={lookupref}
                autocomplete="none"
                // onKeyDownCapture={(e) => { handleOnKeyDown(e) }}
                onBlur={(e) => { handleOnBlurLookup(e) }}
                onFocus={(e) => handleOnFocusLookup(e)}
                // onChange={(e) => handleOnChangeLookup(e)}
                invalidClass={IsFilled.ADDRESS && !IsFilled.ADDRESS?.filled && "Invalid-input"}
                aria-invalid={IsFilled.ADDRESS && !IsFilled.ADDRESS?.filled}
                aria-describedby="address-lookup-errors"
                label={t('checkout.addresslabel')}
                    placeholder={props?.isGift === "gift" ? t('placeholder.recipientaddresssearch') : t('placeholder.addresssearch') }
                
                {...props} />
          </div>
      
        {IsFilled.ADDRESS && !IsFilled.ADDRESS?.filled &&
            <span role="alert" id="address-lookup-errors">
                <ErrorBox>{format(t('addressfields.blankerrortext'), "Address")}</ErrorBox>
            </span>
        }
    </div>

        {changeAddressFormatLink("MANUAL")}
    </>

    return (
        <>   
            {addressFormat === "LOOK_UP" ? showAddressLookUp ? <> {addressLookup}</> :  <> {addressSection} </> :  ""}

            {addressFormat === "MANUAL" ? addressSection : ""}
        </>
    );
}

