import React, { useState, useRef, forwardRef, useEffect, useImperativeHandle, } from 'react';
import { useTranslation } from 'react-i18next';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import setStateAsync from '../../Utilities/SetStateAsync';
import { phoneNumberRegex } from '../../Utilities/EmailRegex';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';


export const CapturePhone = forwardRef((props, ref) => {
    const {isPhoneNumberBlank, setIsPhoneNumberInvalid, setIsPhoneNumberBlank, phoneConfiguration, phoneNumber, isPhoneNumberInvalid, setPhoneNumber } = props;
    const { t, i18n } = useTranslation();
    const [phoneNum, setPhoneNum] = useState("");
   
    useImperativeHandle(ref, ()=>({
     validatePhoneNumber
    }))


    const validatePhoneNumber = (e, type) => {

        let value="";

        if(type==="onSubmit"){
                value=phoneNumber; } 
        else {
                value = e.target.value;}
        
        if (value.length < 1) {
            
            setIsPhoneNumberInvalid(false)

            if(phoneConfiguration==="Required"){
               setIsPhoneNumberBlank(true)
               return false;

            }else if(phoneConfiguration==="Optional"){
               setIsPhoneNumberBlank(false)
               return true;
            }
                
            }
            else {
                setIsPhoneNumberBlank(false)
                if ((value?.match(phoneNumberRegex) || []).length <= 0) {
                    setIsPhoneNumberInvalid(true)
                    return false;
                }
                else {
                    setIsPhoneNumberInvalid(false)
                    return true;
                }
            }}


            const handleOnBlur=(e)=>{
              setPhoneNumber(e.target.value);
              if(phoneConfiguration === "Required" || phoneConfiguration === "Optional"){
              validatePhoneNumber(e, "");
              }  
            }

       return (
              <><div class="item-container">
                    <InputWrapperRefacture
                        label={t('payment.phoneNumberLabel')}
                        inputSize="medium"
                        inputFieldType="text"
                        id="phone"
                        name="phone"
                        type="text"
                        specs="text-input"
                        value={phoneNum}
                        optionalField={(phoneConfiguration === "Optional") && true}
                        onBlur={(e)=>{ handleOnBlur(e) ; } }
                        onChange={(e) => { let value = e.target.value; setPhoneNum(value) }}
                        invalidClass={((isPhoneNumberBlank) || isPhoneNumberInvalid) && (phoneConfiguration === "Required" || phoneConfiguration === "Optional" ) && "Invalid-input"}
                        aria-invalid={(phoneConfiguration === "Required") && isPhoneNumberBlank}
                        aria-describedby="phone-blank-error"
                    />
                   
                   {(phoneConfiguration === "Required" || phoneConfiguration === "Optional") && isPhoneNumberInvalid && <span role="alert" id='phone-blank-error'> <ErrorBox>{t("error.invalidphonenumber")}</ErrorBox></span>} 
                   {phoneConfiguration === "Required" && 
                   (phoneNumber?.length <= 0 && isPhoneNumberBlank) && <span role="alert" id='phone-blank-error'>  <ErrorBox>{t("error.blankphonenumber")}</ErrorBox> </span> } 
                 </div>
               </>
              
    );



})
