import { APIPost } from "../API/APIRequest";
export { loqateRequestLogs, splitName, removeQueryParams};


const loqateRequestLogs = (Id, response, type) => {

    let basketId=sessionStorage.getItem("fid");
    let brand=sessionStorage.getItem("Brand_null")
    let brandData= JSON.parse(brand);
    
    const data = new FormData();
            data.append("sessionid", Id);   //id for successful loqate request
            data.append("request", JSON.stringify(response));  //data for successful loqate request
            data.append("requesttype", type); 
            data.append("brandid", brandData.brandid); 
            data.append("basketid", basketId); 
            

         APIPost({
                "controller": "loqate",
                "action": "logloqaterequest",
                "environment":null,
                 data: data
               }).then((response)=>{
                if(response.status===200){
                    console.log("Log for loqate request created successfully") 
                }
                }).catch((error)=>{
                console.log("error in creating loqate log")
               })

}


 const splitName= (fullName)=> {
   
     try {

         if (typeof fullName !== 'string') {
             throw new Error("Input must be a string");
         }

         let trimmedName = fullName?.trim();
         let nameParts = trimmedName?.split(/\s+/);


         if (nameParts && nameParts.length > 0) {

             let firstName = nameParts[0];

             //everything after first name is going to be last name
             let lastName = nameParts.slice(1).join(' ');
             return [firstName, lastName];

         } else {

             return ["", ""];

         }
     } catch (e) {
         console.log("Error in processing the name input");
         return ["", ""];
     }
}

//for removing query params from the urls.

//need the url for this and the destinationPageName which is type here to make it consisten all throughout
function removeQueryParams(url, type) {

    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const searchParams = new URLSearchParams(urlObj.search);

    let paramsToRemove="";

    // Please add the query param that you want to be deleted here

    if(type==="thankyouPage"){
        paramsToRemove=["purchasingFor","returl","payment_intent","payment_intent_client_secret","redirect_status"]
    }
  
    // Remove specified parameters
    paramsToRemove.forEach(param => {
        searchParams.delete(param);
    });

    // Update the URL with the the remaining needed search params
    urlObj.search = searchParams.toString();

    return url+urlObj.search;
}


