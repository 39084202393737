import React, { Component, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import '../../i18n'
import { APIUrl } from '../Utilities/APIUrl'
import { Loading } from '../Utilities/Loading'
import { ErrorBox } from '../Utilities/ErrorBox'
import { Basket } from '../Order/Payment/Basket';
import { LoginInfo } from '../Authentication/Account/LoginInfo';
import { APIGet, APIPost } from '../API/APIRequest';
import BuyingForMyself from '../ProductOffer/WhoIsItFor';
import WhoIsItFor from '../ProductOffer/WhoIsItFor';

export function TestProductOffer(props) {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [productVariants, setProductVariants] = useState([]);
    const [basket, setBasket] = useState({});
    const [showPopup, setShowPopup]=useState(false);

    useEffect(() => {
        getBasket();
        loadData();
    }, [updating])

    var { fid } = useParams();

    const getBasket = () => {
    
        if (!fid && basket?.id) {
            fid = basket.id;
        }

        APIGet({
            "controller": "basket",
            "action": "getbasket",
            "environment": null,
            "identifier": fid
        }).then((response) => {
            if (response.data.success) {
              
                setBasket(response.data.basket);
                if (!fid) {
                    var newPath = window.location.protocol + "//" + window.location.host;
                    if (window.location.pathname) {
                        newPath += window.location.pathname;
                    }
                    newPath += response.data.basket.id;
                    window.history.pushState({ path: newPath }, '', newPath);
                }
                
            }
            else {
                //setErrors(response.data.errors);
            }
            setLoading(false);
        });
        
    }

    const loadData = () => {
        
        APIGet({
            "controller": "product",
            "action": "getproductvariants",
            "environment": null
        }).
            then((response) => {
               
            setProductVariants(response.data);
        });
    }

    const SetCookie = () => {

        APIPost({
            "controller": "auth",
            "action": "setcookie",
            "environment": null,
            "data": {
                value: "Chiswick ! Fresh Horses!"
            }
        }).
            then((response) => {
                       
            });
    }

    const GetCookie = () => {

        APIGet({
            "controller": "auth",
            "action": "getcookie",
            "environment": null
        }).
            then((response) => {
                  
            });
    }

    const addToBasket = (event) => {
        event.preventDefault();
        var variantId = event.target.getAttribute('data-variant-id');
        var basketId = basket.id;
    
        var data = new FormData();
       
        data.append("productVariantId", variantId);
        data.append("quantity", 1);

        
        APIPost({
                "controller": "basket",
                "action": "addtobasket",
                "environment" : null,
                "identifier" : basket.id,
                "data" : data
            }).then((response) => {
       
            setBasket(response.data.basket);
            setUpdating(!updating);
        });
    }

    const Checkout = () => {    
        window.location.href = 'checkout/' + basket.id         
    }

    const displayData = () => {
        
        return (<div>
            <table>
                <tbody>
                    {
                        productVariants?.map((productVariant) => (
                            <tr>
                                <td>
                                    {productVariant.productvariantname}
                                </td>
                                <td>
                                    {productVariant.price}
                                </td>
                                 <td>
                                    <a href="#" data-variant-id={productVariant.productvariantid} onClick={addToBasket}>Add to basket</a>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            </div>
        )
    }
   
    var content = displayData();
    return (
        <div className="product-offer">
            <div class="left-column">
                <LoginInfo />
                <h4>Test Product Offers</h4>
                <Loading loading={props.loading} />
                {content}
            </div>
            <div class="right-column">
                <Basket basket={basket} />
                <button className="btn btn-primary" type="submit" onClick={Checkout}>Checkout</button>
                <button className="btn btn-primary" type="submit" onClick={SetCookie}>Set Cookie</button>
                <button className="btn btn-primary" type="submit" onClick={GetCookie}>Get Cookie</button>
            </div>

            {/* {showPopup ? 
            <div className="whoisitfor-block">
                <WhoIsItFor basket={basket}  showPopup={showPopup} setShowPopup={setShowPopup}/>
            </div>
            :""} */}
        </div>
    )
  
}