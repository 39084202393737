import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthenticationProvider';
import { GetCurrentBrand } from '../Configuration/ConfigurationManager';
import { GetBasket } from '../Order/Payment/Basket.js';
import { GenericErrorBox } from '../UI/ErrorBox/GenericErrorBox';
import { Loading } from './Loading';
import { GetADToken } from './TokenManager';

export function PrivateRoute({ children }) {
    const [currentAction, setCurrentAction] = useState([]);
    const [basket, setBasket] = useState(null);
    const { environment } = useParams();
    const { fid } = useParams();
    const params = new URLSearchParams(window.location.search);
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [guestUser, setGuestUser] = useState(location.state ? location.state.guestUser : "");
    const [guestSession, setGuestSession] = useState(location.state ? location.state.guestSession : "");
    const purchasingFor = params.get("purchasingFor");

    const isValidHttpUrl = (string) => {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    const GetBasketForGuestCheck = (purchaseTarget) => {
        let basket = {};

         GetBasket({ fid: fid, loadfromserver: true, purchaseTarget: purchaseTarget }).then((result) => {
            basket = result;
           

            if (!basket.allowcustomercheckout || guestSession) {
                // let newPath = window.location.pathname.replace('payment', 'guest');
                // window.location.pathname = newPath;

                let url = '/guest/' + basket.id + window.location.search;

                navigate(url, { state: location.state, replace: true });
            }
            else {
                setBasket(basket);
            }
        }).catch((error) => {

        })
    }

    const RedirectToMiddleware = () => {
       
        let loginURL = '';
        GetCurrentBrand(null).then((brand) => {
          
            let environment = window.location.hostname;
            if (environment === 'localhost') {
                environment = 'ad-frontend.abasoft.co.uk';
            }


            let queryParams = new URLSearchParams(window.location.search);

            // Check if the 'logout' parameter is set to 'true'
            const isLoggingOut = queryParams.get('logout') === 'true';
            if (isLoggingOut) {
                loginURL = brand.authmiddlewareurl + '/' + environment + '/logout?ReturnUrl='

            } else {

                loginURL = brand.authmiddlewareurl + '/' + environment + '/login?ReturnUrl='
            }


            loginURL += window.location.protocol + "//" + window.location.host + window.location.pathname;

            if (window.location.search) {
                for (var [key, value] of params) {
                    if (isValidHttpUrl(value)) {
                        params.set(key, encodeURI(value));
                    }
                }
                loginURL += "?" + params.toString();
            }
          
            if (basket.allowguestcheckout && window.location.pathname.startsWith('/payment')) {
                loginURL += '&PassThruUrl=';
                loginURL += window.location.protocol + "//" + window.location.host + window.location.pathname.replace('payment', 'guest');
                if (window.location.search) {
                    loginURL += "?" + params.toString();
                }
            }
         
            window.location.href = loginURL;
        }).catch((errors) => {
                 
        });
        
    }

    useEffect(() => {
        if (!basket) {
            if (window.location.pathname.startsWith('/payment')) {
                GetBasketForGuestCheck(purchasingFor || "unknown");
                return <Loading loading={true} />;
            }
        }
    }, [])

    let errors = auth.errors;
    let userToken = auth.userData || guestUser;
    if (errors.length > 0) {
        return <GenericErrorBox errors={errors} />;
    }

    if (!userToken && !userToken.anon) { 
        userToken = GetADToken();
        if (!userToken) {
            auth.ReloadUserData();  
        }
    }
    console.log(userToken);
    if (auth.isLoading) {
        return <Loading loading={true} />;
    }

    // sessionStorage.getItem("guestUser");

   if (userToken?.anon) {
        // not logged in so redirect to login page with the return url
        RedirectToMiddleware();
        return <Loading loading={true} />;
    }

    // authorized so return child components
    return children;
}

export default PrivateRoute;