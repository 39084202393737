import InputWrapperRefacture from "../../UI/Inputs/InputWrapperRefacture";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import ErrorBoxCS from '../../UI/ErrorBox/ErrorBox';
import { APIPost, getEnvironment } from "../../API/APIRequest";
import SuccessBox from "../../UI/SuccessBox/SuccessBox";




export default function PromoCode(props) {


    const { fid, basket, purchasingFor, deliveryCountryCode, adToken, setActionName } = props;
    const [promoCode, setPromoCode] = useState("");
    const [errorStates, setErrorStates] = useState({ "invalid": false, "blank": false })
    const [successStates, setSuccessStates] = useState({ "applied": false, "removed": false })
    const { t, i18n } = useTranslation();
   
    const [tagValue, setTagValue] = useState("");
    const [variants, setVariants] = useState("");
    const [quantities, setQuantities] = useState("");
    const [tagCounts, setTagCounts] = useState("");

    const discountCode = sessionStorage.getItem("discountApplied");
    const prevBasketFid = sessionStorage.getItem("prevFid");
    const params = new URLSearchParams(window.location.search);
    const discountRemoved= params.get("discountRemoved"); //queryParam

    
    const getPromoCodePayloadValues = () => {

        let tagCounts = {};
        let variants = [];
        let quantities = [];
        let tagValue = [];

        basket.lines.forEach(variant => {

            variants.push(variant?.productvariantid);  // for variants
            quantities.push(variant?.quantity);  // for quantity

            if (variant.tags && Array.isArray(variant.tags)) {
                variant.tags.forEach(tagObj => {
                    tagValue.push(tagObj.tag);    // for tag value
                    const tag = tagObj.tag;
                    if (tagCounts[tag]) {
                        tagCounts[tag] += variant.quantity;    //for tag counts
                    } else {
                        tagCounts[tag] = variant.quantity;
                    }
                });
            }
        });

        setTagCounts(tagCounts);
        setTagValue(tagValue.join(','));
        setQuantities(quantities.join(','));
        setVariants(variants.join(','));
    }
    
    function removeQueryParam(param) {
       
        const url = new URL(window.location.href);
        
        if (url.searchParams.has(param)) {
            url.searchParams.delete(param);
            
            window.history.replaceState({}, '', url); //need to replace the url without refresh
        }
    }
    
    useEffect(() => {

        getPromoCodePayloadValues();

        if (discountCode) {
            setPromoCode(discountCode)     // on application of discount code 
            setSuccessStates({ applied: true, removed: false })
            if (adToken) {
                setActionName("Order Discount Code")   //discount code web activity.
               
            }
        }
        else {
            if (discountRemoved) {             // on removal of discount code
                setSuccessStates({ applied: false, removed: true })
                removeQueryParam("discountRemoved");  //only should show up for the first time
            }
        }

    }, [])


    const removePromoCode = () => {
       
        let baseUrl = "";
        if(adToken?.isGuest){
           baseUrl = '/guest/' + prevBasketFid;
        }else{
           baseUrl = '/payment/' + prevBasketFid; 
        }
      
        const existingParams = window.location.search;
        const newParam = "discountRemoved=true";

        let prevBasketUrl = baseUrl;

        // To check for existing query parameters
        if (existingParams) {
            prevBasketUrl += existingParams + "&" + newParam;
        } else {
            prevBasketUrl += '?' + newParam;
        }
        
        sessionStorage.removeItem("discountApplied");   // removing discount code
        sessionStorage.removeItem("purchasingFor");    // removing session variables which were to be used for bypassing landingPage on discount code application
        sessionStorage.removeItem("prevBasketDataForDiscountFlow");
        sessionStorage.removeItem("prevFid");

        window.location.href = prevBasketUrl   // redirection to prev basket
    }

    const applyPromoCode = (e) => {
       
        let validated = validationOnApply();

        if (validated) {

            const data = new FormData();
            data.append("discountCode", promoCode);
            data.append("tagValues", tagValue);
            data.append("quantity", quantities);
            data.append("variantIDs", variants);
            data.append("tagQuantity", JSON.stringify(tagCounts));
            data.append("origin", window.location.hostname);

            APIPost({
                "controller": "basket",
                "action": "variantmappings",
                "environment": null,
                "identifier": fid,
                data: data
            }).then((response) => {
                if (response.status === 200) {
                    if (response?.data) {
                        if (response?.data?.newUrl) {

                            let discountCode = response?.data?.discountCode;
                            let discountParam= `discountApplied=${discountCode}`
                            let newUrlWithDiscount = response?.data?.newUrl;

                            let existingParams = window.location.search;
                            // Remove the leading "?" from existingParams if it exists as the fid in newUrl is queryparam too.
                            existingParams = existingParams.startsWith('?') ? existingParams?.slice(1) : existingParams;

                           // To check for existing query parameters
                            if (existingParams) {
                                newUrlWithDiscount += "&" + existingParams + "&" + discountParam;
                            } else {
                                newUrlWithDiscount += '?' + discountParam;
                            }
                            
                            newUrlWithDiscount = new URL(newUrlWithDiscount).toString();

                            sessionStorage.setItem("purchasingFor", purchasingFor);    //creating session variables with data of existing basket for bypassing landingPage
                            sessionStorage.setItem("prevBasketDataForDiscountFlow", JSON.stringify(basket));
                            sessionStorage.setItem("prevFid", fid);
                            sessionStorage.setItem("prevDeliveryCountryCode", deliveryCountryCode);


                            window.location.href = newUrlWithDiscount;        //redirection to new basket with discount code applied
                        }
                        else {
                            if (response?.data?.error) {
                                let er = response?.data?.error;    //in case of invalid discount code
                                if (er === "No mapped variants found - discount code probably invalid") {
                                    setErrorStates(errorStates => ({ ...errorStates, invalid: true }))
                                }
                            }
                        }
                    }
                }
            }).catch((error) => {
                console.error('Error:', error);

            });
        }
    }

    const handleOnChange = (value) => {
        setPromoCode(value);

        // reinitialising error states on input change
        setSuccessStates({ applied: false, removed: false })
        setErrorStates({ invalid: false, blank: false })
    }
    
    
    // validation on "apply" button click
    const validationOnApply = () => {
     
        let valid = true;
        // reinitialising error states on input change
        setSuccessStates(successStates=>({...successStates, removed: false }))
        setErrorStates(errorStates => ({ ...errorStates, blank: false, invalid: false }))

        if (promoCode.length < 1) {
            setErrorStates(errorStates => ({ ...errorStates, blank: true }))
            valid = false;
        }

        return valid;
    }

    const promoFieldUI = () => {
        return <div className="item-wrapper">

            <InputWrapperRefacture
                label={t('payment.promocodelabel')}
                inputSize="full-width"
                inputFieldType="text"
                btnText={successStates?.applied ? "Remove" : "Apply"}
                value={promoCode}
                id="promoCode"
                name="promoCode"
                placeholder={t('payment.promocodeplaceholder')}
                onClickFunction={discountCode ? () => removePromoCode() : () => applyPromoCode(promoCode)}
                aria-invalid={errorStates?.invalid || errorStates?.blank}
                aria-describedby="promocode-alerts"
                invalidClass={(errorStates?.invalid || errorStates?.blank) ? "Invalid-input" : ""}
                onChange={discountCode ? "" : (e) => handleOnChange(e.target.value)} />

            {errorStates?.blank && <span role="alert" id="promocode-alerts"><ErrorBoxCS>{t('payment.blankpromocode')}</ErrorBoxCS></span>}
            {errorStates?.invalid && <span role="alert" id="promocode-alerts"><ErrorBoxCS>{t('payment.invalidpromocode')}</ErrorBoxCS></span>}
            {successStates?.applied && <span role="alert" id="promocode-alerts"><SuccessBox>{t('payment.promocodeadded')}</SuccessBox></span>}
            {successStates?.removed && <span role="alert" id="promocode-alerts"><SuccessBox>{t('payment.promocoderemoved')}</SuccessBox></span>}

        </div>
    }



    return (
        <div>
            {promoFieldUI()}
          
        </div>
    )
}